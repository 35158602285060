.AppFrame {
  text-align: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.AppFrame {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 300px;
  font-size: calc(10px + 2vmin);
  color: #EEEEEE;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh - 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 300px;
  font-size: calc(10px + 2vmin);
  color: #EEEEEE;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 추가한 클래스에 대한 스타일 지정 */
.current-chord {
  font-size: 128px; /* 원하는 크기로 변경 */
  color: #EEEEEE; /* 원하는 색상으로 변경 */
}

.next-chord {
  font-size: 64px; /* 원하는 크기로 변경 */
  color: gray; /* 원하는 색상으로 변경 */
}

.bpm {
  font-size: 128px; /* 원하는 크기로 변경 */
  color: #EEEEEE; /* 원하는 색상으로 변경 */
}

.settings-btn {
  position: absolute;
  top: 20px; /* 원하는 위치로 조정 */
  right: 20px; /* 원하는 위치로 조정 */
  background: none;
  border: none;
  font-size: 100px;
  color: #EEEEEE; /* 아이콘 색상 */
  cursor: pointer;
}

/* 버튼 그룹 스타일링 */
.btn-group {
  margin-top: 100px;
  margin-bottom: 300px;
}

.btn-group button {
  margin-right: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #3498db; /* 버튼 배경색 변경 */
  color: #EEEEEE; /* 버튼 텍스트 색상 변경 */
  border-radius: 5px; /* 버튼을 둥글게 만듦 */
  font-size: 36px; /* 버튼 안의 텍스트 크기 설정 */
}

.btn-group button:hover {
  background-color: #2980b9;
}

/* BPM 입력창 스타일링 */
.settings input[type="number"] {
  font-size: 60px; /* 입력창 글씨 크기 조정 */
  width: 300px; /* 입력창 가로 폭 설정 */
}

.banner {
  width: 720px;
  height: 140px;
  object-fit: cover;
}